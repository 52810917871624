// React
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useWindowSize } from 'react-use';
// Atom
import { selectedDbAtom } from '../atom/selectedDbAtom';
import { vectorDbAtom } from '../atom/vectorDbAtom';
import { vectorDbApiConfigAtom } from '../atom/vectorDbApiConfigAtom';
// Type
import { selectedDbType } from '../type/selectedDbType';
import { vectorDbTypes } from '../type/vectorDbTypes';
import { apiConfigType } from '../type/apiConfigType';
// React Component
import NavigationUi from '../navigation/NavigationUi';
import MobileHeader from './MobileHeader';
// Utility
import getDB from '../utils/getDB';
// CSS
import './css/layout_root.css';
import { isMobileAtom } from '../atom/isMobileAtom';
import { isMobileType } from '../type/isMobileType';
// etc
import { BREAK_POINT } from '../params';

export default function LayoutRootPage(){
    // 設定保持用のRecoil
    const vectorDbApiConfigRecoil = useRecoilValue<apiConfigType>(vectorDbApiConfigAtom);
    // DB保存用のRecoil
    const setVectorDbRecoil = useSetRecoilState<vectorDbTypes>(vectorDbAtom);
    // 選択済みDB保持用のRecoil
    const setSelectedDbRecoil = useSetRecoilState<selectedDbType>(selectedDbAtom);

    // ページロード時のDB一覧取得処理（初回ロード時のみ実行）
    useEffect(() => {
        (async() => {
            if(vectorDbApiConfigRecoil.isActive){
                try {
                    const vectorDb = await getDB(vectorDbApiConfigRecoil.endpoint, vectorDbApiConfigRecoil.token);
                    setVectorDbRecoil(vectorDb.data);
                    setSelectedDbRecoil(0 < vectorDb.data[0].value.length ? vectorDb.data[0].value : '');
                }catch(error){
                    console.log(error);
                    setVectorDbRecoil([]);
                    setSelectedDbRecoil('');
                    alert('APIエラー\nベクターDB用エンドポイント及びアクセストークンの設定を確認してください。');
                    return;
                }
            }
        })();
    }, []);

    // レスポンシブ関連処理
    const [isMobileRecoil, setIsMobileRecoil] = useRecoilState<isMobileType>(isMobileAtom);
    const { width, height } = useWindowSize();
    useEffect(() => {
        if(width < BREAK_POINT){
            setIsMobileRecoil(true);
        }else{
            setIsMobileRecoil(false);
        }
    }, [width]);

    return (
        <React.StrictMode>
            <>
                <div className='index-top-wrapper'>
                    <NavigationUi />
                    <div className='layout-content-wrapper'>
                        {isMobileRecoil && <MobileHeader />}
                        <Outlet />
                    </div>
                </div>
            </>
        </React.StrictMode>
    );
}
