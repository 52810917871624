import { maxChatHistoryType } from '../type/maxChatHistoryType';
import { decrypt, encrypt } from './../utils/crypto';

// 初期値の宣言
const defaultMaxChatHistory: maxChatHistoryType = 100;
const localStorageKey = 'maxChatHistory';

// LocalStorageから取得
function getMaxChatHistoryLS(){
    const encryptedMaxChatHistory: string | null = localStorage.getItem(localStorageKey);
    const decryptedMaxChatHistory: string | null = encryptedMaxChatHistory ? decrypt(encryptedMaxChatHistory) : null;
    const numberMaxChatHistory: maxChatHistoryType = decryptedMaxChatHistory ? parseInt(decryptedMaxChatHistory) : defaultMaxChatHistory;
    return numberMaxChatHistory;
}

// LocalStorageへの保存
function setMaxChatHistoryLS(max: number){
    const encryptedMaxChatHistory: string = encrypt(max.toString());
    localStorage.setItem(localStorageKey, encryptedMaxChatHistory);
}

// LocalStorageから削除
function deleteMaxChatHistoryLS(){
    localStorage.removeItem(localStorageKey);
}

export {
    getMaxChatHistoryLS
    , setMaxChatHistoryLS
    , deleteMaxChatHistoryLS
}
