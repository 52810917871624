import React from 'react';
import ChatUi from './ChatUi';
import PageCloseAlertDialog from '../utils/PageCloseAlertDialog';

export default function TopPage(){
    return (
        <React.StrictMode>
            <PageCloseAlertDialog />
            <ChatUi />
        </React.StrictMode>
    );
}
