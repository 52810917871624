import { linkType } from "../type/chatTypes";

type ChatUiViewLinkProps = {
    link: linkType
}

export default function ChatUiViewLink({link}: ChatUiViewLinkProps){
    console.log(link);
    return (
        <>
            <div className='link'>
                <a href={link.url} rel='noreferrer' target='_blank'>{link.name}</a>
            </div>
        </>
    );
}
