// React
import React from 'react';
// React Component
import NavigationUi from '../navigation/NavigationUi';
import ErrorUi from './RoutingErrorUi';

export default function RoutingErrorPage(){
    return (
        <React.StrictMode>
            <>
                <div className='index-top-wrapper'>
                    <NavigationUi />
                    <ErrorUi />
                </div>
            </>
        </React.StrictMode>
    );
}
