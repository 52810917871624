import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { CRYPTO_PASSPHRASE } from '../params';

const SECRET_KEY = CRYPTO_PASSPHRASE;

export const encrypt = (plainText: string): string => {
    const encrypted = AES.encrypt(plainText, SECRET_KEY);
    return encrypted.toString();
}

export const decrypt = (cipherText: string): string => {
    try{
        const decrypted = AES.decrypt(cipherText, SECRET_KEY);
        return decrypted.toString(Utf8);
    }catch{
        return '';
    }
}
