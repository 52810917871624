// CSS
import './css/header_log.css';
// Image
import header_log from './img/header_log.png';

export default function HeaderLog(){
    return (
        <>
            <div className='header-log-wrapper'>
                <img src={header_log} alt='vantiqロゴ' />
                <div>
                    <span>Vantiq</span>
                    <span>Chat App</span>
                </div>
            </div>
        </>
    );
}
