// React
import { useRecoilValue } from 'recoil';
// Type
import { isMobileType } from '../type/isMobileType';
// Atom
import { isMobileAtom } from '../atom/isMobileAtom';
// React Component
import NavigationMobile from './NavigationMobile';
import NavigationPc from './NavigationPc';
// CSS
import './css/navigation_ui.css';
// etc

export default function NavigationUi(){
    // レスポンシブ関連処理
    const isMobileRecoil = useRecoilValue<isMobileType>(isMobileAtom);

    return (
        <>
            {isMobileRecoil && <NavigationMobile />}
            {!isMobileRecoil && <NavigationPc />}
        </>
    );
}
