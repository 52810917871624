// React
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
// CSS
import './css/routing_error_ui.css';
// Image
import { ReactComponent as ExclamationIcon } from './img/exclamation_icon.svg';

export default function RoutingErrorUi(){
    const error = useRouteError();
    console.log(error);
    if(isRouteErrorResponse(error)){
        return (
            <>
                <div className='error-wrapper'>
                    <div className='error-content-wrapper'>
                        <ExclamationIcon />
                        <p className='error-status'>{error.status}</p>
                        <p className='error-statusText'>{error.statusText}</p>
                        {error.data?.message && <p className='error-message'>{error.data.message}</p>}
                    </div>
                </div>
            </>
        );
    }else{
        return null;
    }
}
