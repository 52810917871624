/**
 * 文字列内のURLをリンクに変換します。
 * @param {string} text - 変換したい文字列
 * @return {string} - 変換済みの文字列
 */
export default function textToLink(text: string, target: string = '_self'){
    const regex = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
    let replacedText = text.replace(regex, `<a href="$1" rel="noreferrer" target="${target}">$1</a>`);

    return replacedText;
}
