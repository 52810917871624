// Recoil
import { useState } from 'react';
import { useRecoilState } from 'recoil';
// Type
import { maxChatHistoryType } from '../type/maxChatHistoryType';
// Atom
import { maxChatHistoryAtom } from '../atom/maxChatHistoryAtom';
import { chatHistoryAtom } from '../atom/chatHistoryAtom';
// LocalStorage
import { setMaxChatHistoryLS } from '../local_storage/useMaxChatHistoryLS';
import { setChatHistoryLS } from '../local_storage/useChatHistoryLS';
// Utility
import overFitArray from '../utils/overFitArray';

export default function MaxChatHistoryUi(){
    // パラメータ設定
    const systeMinChatHistory = 10;
    const systeMaxChatHistory = 1000;
    // 設定ボタン活性処理用のState
    const [saveDisabledState, setSaveDisabledState] = useState<boolean>(true);
    // チャット履歴最大件数のRecoil
    const [maxChatHistoryRecoil, setMaxChatHistoryRecoil] = useRecoilState<maxChatHistoryType>(maxChatHistoryAtom);
    // チャット履歴最大件数一時保持用のState
    const [maxChatHistoryState, setMaxChatHistoryState] = useState<maxChatHistoryType>(maxChatHistoryRecoil);
    // チャット履歴のRecoil
    const [chatHistoryRecoil, setChatHistoryRecoil] = useRecoilState(chatHistoryAtom)
    // 保存ボタン押下時の処理
    const handleSaveMaxChatHistory = () => {
        setSaveDisabledState(true);
        setMaxChatHistoryRecoil(maxChatHistoryState);
        setMaxChatHistoryLS(maxChatHistoryState);
        const newChatHistory = overFitArray(chatHistoryRecoil, maxChatHistoryState);
        setChatHistoryRecoil(newChatHistory);
        setChatHistoryLS(newChatHistory);
    }
    // 入力ボックス入力時の処理
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMaxChatHistory = parseInt(event.target.value);
        setMaxChatHistoryState(newMaxChatHistory);
        if(
            newMaxChatHistory === maxChatHistoryRecoil
            || Number.isNaN(newMaxChatHistory)
            || newMaxChatHistory < systeMinChatHistory
            || systeMaxChatHistory < newMaxChatHistory
        ){
            setSaveDisabledState(true);
        }else{
            setSaveDisabledState(false);
        }
    }

    return (
        <div className='sub-content-wrapper'>
            <div>
                <h3>チャット履歴の最大保存件数</h3>
                <p>
                    <span>チャットの履歴の最大保存件数を設定します。</span>
                    <span>※チャット履歴は{systeMinChatHistory}～{systeMaxChatHistory}件の間で入力してください。</span>
                </p>
                <input value={maxChatHistoryState} onChange={handleChangeInput} type='number' min={systeMinChatHistory} max={systeMaxChatHistory} step='10'></input>
            </div>
            <div className='button-wrapper'>
                <button onClick={handleSaveMaxChatHistory} disabled={saveDisabledState}>保存</button>
            </div>
        </div>
    );
}
