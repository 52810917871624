import { messagesStateType } from '../type/chatTypes';
import { decrypt, encrypt } from './../utils/crypto';

// 初期値の宣言
const defaultChatHistory: messagesStateType = [];
const localStorageKey = 'chatHistory';

// LocalStorageから取得
function getChatHistoryLS(){
    const encryptedChatHistory: string | null = localStorage.getItem(localStorageKey);
    const decryptedChatHistory: string | null = encryptedChatHistory ? decrypt(encryptedChatHistory) : null;
    const arrayChatHistory: messagesStateType = decryptedChatHistory ? JSON.parse(decryptedChatHistory) : defaultChatHistory;
    return arrayChatHistory;
}

// LocalStorageへの保存
function setChatHistoryLS(chatHistory: messagesStateType){
    const encryptedChatHistory: string = encrypt(JSON.stringify(chatHistory));
    localStorage.setItem(localStorageKey, encryptedChatHistory);
}

// LocalStorageから削除
function deleteChatHistoryLS(){
    localStorage.removeItem(localStorageKey);
}

export {
    getChatHistoryLS
    , setChatHistoryLS
    , deleteChatHistoryLS
}
