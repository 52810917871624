import textToLink from '../utils/textToLink';
import newlinecodeToBrtag from '../utils/newlinecodeToBrtag';

type ChatUiViewTextProps = {
    text: string
}

export default function ChatUiViewText({text}: ChatUiViewTextProps){
    return (
        <>
            <div className='text'>
                <p dangerouslySetInnerHTML={{__html: newlinecodeToBrtag(textToLink(text, '_blank'))}}></p>
            </div>
        </>
    );
}
