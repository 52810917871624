import { apiConfigType } from '../type/apiConfigType';
import { decrypt, encrypt } from './../utils/crypto';

// 初期値の宣言
const defaultAPiConfig: apiConfigType = {
    endpoint: ''
    , token: ''
    , isActive: false
};

// LocalStorageから取得
function getApiConfigLS(key: string){
    const encryptedApiConfig: string | null = localStorage.getItem(key);
    const decryptedApiConfig: string | null = encryptedApiConfig ? decrypt(encryptedApiConfig) : null;
    const objectApiConfig: apiConfigType = decryptedApiConfig ? JSON.parse(decryptedApiConfig) : defaultAPiConfig;
    const margedApiConfig = {
        ...defaultAPiConfig
        , ...objectApiConfig
    }
    return margedApiConfig;
}

// LocalStorageへの保存
function setApiConfigLS(key: string, config: apiConfigType){
    const encryptedApiConfig: string = encrypt(JSON.stringify(config));
    localStorage.setItem(key, encryptedApiConfig);
}

export {
    getApiConfigLS
    , setApiConfigLS
}
