type ChatUiViewDateProps = {
    date: string
}

export default function ChatUiViewDate({date}: ChatUiViewDateProps){
    return (
        <>
            <div className='date'>
                <span>{date}</span>
            </div>
        </>
    );
}
