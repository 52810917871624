// React
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
// React Component
import RecoilRootPage from './root/RecoilRootPage';
import RoutingErrorPage from './error/RoutingErrorPage';
import ChatPage from './chat/ChatPage';
import SettingPage from './setting/SettingPage';

const RoutingTable = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<RecoilRootPage />} errorElement={<RoutingErrorPage />}>
            <Route path='/' element={<ChatPage />} />
            <Route path='/setting' element={<SettingPage />} />
        </Route>
    )
);

export default RoutingTable;
