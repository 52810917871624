// React
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
// Type
import { apiConfigType } from '../type/apiConfigType';
import { conversationIdType } from '../type/conversationIdType';
// Atom
import { chatApiConfigAtom } from '../atom/chatApiConfigAtom';
import { conversationIdAtom } from '../atom/conversationIdAtom';
// LocalStorage
import { setApiConfigLS } from '../local_storage/useApiConfigLS';
import { deleteConversationIdLS } from '../local_storage/useConversationIdLS';
// Utility
import objectCompare from '../utils/objectCompare';
import checkVantiqApi from '../utils/checkVantiqApi';

export default function ChatApiConfigUi(){
    // パラメータ
    const localStorageKey = 'chatApiConfig';
    // 保存ボタン活性処理用のState
    const [saveDisabledState, setSaveDisabledState] = useState<boolean>(true);
    // 設定保持用のRecoil
    const [configRecoil, setConfigRecoil] = useRecoilState<apiConfigType>(chatApiConfigAtom);
    // 設定一時保持用のState
    const [configState, setConfigState] = useState<apiConfigType>(configRecoil);
    // ConversationId管理用のRecoil
    const setConversationIdRecoil = useSetRecoilState<conversationIdType>(conversationIdAtom);

    // 入力ボックス入力時の処理
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.dataset.key;
        const value = event.target.value;
        if(key){
            const newConfig: object = {
                [key]: value
            }
            const newConfigs: apiConfigType = {
                ...configState
                , ...newConfig
            }
            setConfigState(newConfigs);
            if(objectCompare(newConfigs, configRecoil)){
                setSaveDisabledState(true);
            }else{
                setSaveDisabledState(false);
            }
        }
    }
    // 保存ボタン押下時の処理
    const handleSaveConfig = async () => {
        // 保存ボタンの非活性化
        setSaveDisabledState(true);
        // 疎通チェック
        if(configState.endpoint && configState.token){
            try{
                await checkVantiqApi(configState.endpoint, configState.token);
                configState.isActive = true;
            }catch(error: any){
                    configState.isActive = false;
            }
        }else{
            configState.isActive = false;
        }
        // APIエンドポイント変更時にConversationIdを削除
        deleteConversationIdLS();
        setConversationIdRecoil('');
        // LocalStorageとRecoilに保存
        setApiConfigLS(localStorageKey, configState);
        setConfigRecoil(configState);
    }

    return (
        <>
            <div className={'sub-content-wrapper'}>
                <div>
                    <h3>チャットAPIエンドポイント</h3>
                    <p><span>Vantiqのエンドポイントを入力してください。</span></p>
                    <input
                        className={classNames(((!configRecoil.isActive && configRecoil.endpoint) || (!configRecoil.isActive && configRecoil.token)) && 'errorStyle')}
                        data-key={'endpoint'}
                        defaultValue={configRecoil.endpoint}
                        placeholder={'例：https://dev.vantiq.com/api/v1/resources/procedures/ChatAPI'}
                        onChange={handleChangeInput}
                    >
                    </input>
                    {((!configRecoil.isActive && configRecoil.endpoint) || (!configRecoil.isActive && configRecoil.token)) && <p className='errorStyle'><span>入力したエンドポイントまたはアクセストークンが間違っています。</span></p>}
                </div>
                <div>
                    <h3>チャットAPIアクセストークン</h3>
                    <p><span>VantiqのチャットAPI用のアクセストークンを入力してください。</span></p>
                    <input
                        className={classNames(((!configRecoil.isActive && configRecoil.token) || (!configRecoil.isActive && configRecoil.endpoint)) && 'errorStyle')}
                        data-key={'token'}
                        defaultValue={configRecoil.token}
                        placeholder={'例：zzSDpN_XCyO57nQGJXo3NPlb31gTRIf1iAnoyBrnDTc='}
                        onChange={handleChangeInput}
                    >
                    </input>
                    {((!configRecoil.isActive && configRecoil.token) || (!configRecoil.isActive && configRecoil.endpoint)) && <p className='errorStyle'><span>入力したエンドポイントまたはアクセストークンが間違っています。</span></p>}
                </div>
                <div className='button-wrapper'>
                    <button onClick={handleSaveConfig} disabled={saveDisabledState}>保存</button>
                </div>
            </div>
        </>
    );
}
