import axios from 'axios';

export default async function getDB(endpoint: string, token: string){
    const headers = {
        'Content-Type': 'application/json'
        , 'Authorization': 'Bearer ' + token
    }
    const body: object = {};
    const response = axios.post(endpoint, body, {headers: headers});
    return response;
}
