// React
import { useRecoilState } from 'recoil';
// Type
import { drawerOpenStatusType } from '../type/drawerOpenStatusType';
// Atom
import { drawerOpenStatusAtom } from '../atom/drawerOpenStatusAtom';
// CSS
import './css/mobile_header.css';
// Image
import header_log from './img/header_log.png';

export default function MobileHeader(){
    // ドロワー処理関連
    const [drawerOpenStatus, setDrawerOpenStatus] = useRecoilState<drawerOpenStatusType>(drawerOpenStatusAtom);
    const handleDraw = () => setDrawerOpenStatus(!drawerOpenStatus);

    return (
        <>
            <div className='mobile-header-wrapper'>
                <div className='menue-wrapper'>
                    <button onClick={handleDraw} className="menu_button"><span></span></button>
                </div>
                <div className='title-wrapper'>
                    <img src={header_log} alt='vantiqロゴ' />
                    <div>
                        <span>Vantiq Chat App</span>
                    </div>
                </div>
            </div>
        </>
    );
}
