import { conversationIdType } from '../type/conversationIdType';
import { decrypt, encrypt } from './../utils/crypto';

// 初期値の宣言
const defaultConversationId: conversationIdType = '';
const localStorageKey = 'convId';

// LocalStorageから取得
function getConversationIdLS(){
    const encryptedConversationId: string | null = localStorage.getItem(localStorageKey);
    const decryptedConversationId: conversationIdType = encryptedConversationId ? decrypt(encryptedConversationId) : defaultConversationId;
    return decryptedConversationId;
}

// LocalStorageへの保存
function setConversationIdLS(convId: conversationIdType){
    const encryptedConversationId: string = encrypt(convId);
    localStorage.setItem(localStorageKey, encryptedConversationId);
}

// LocalStorageから削除
function deleteConversationIdLS(){
    localStorage.removeItem(localStorageKey);
}

export {
    getConversationIdLS
    , setConversationIdLS
    , deleteConversationIdLS
}
