/**
 * 文字列内の改行コードを<br />タグに変換します。
 * @param {string} text - 変換したい文字列
 * @return {string} - 変換済みの文字列
 */
export default function newlinecodeToBrtag(text: string){
    const regex = /(\n)/g;
    let replacedText = text.replace(regex, '<br />');

    return replacedText;
}
