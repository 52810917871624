// React
import { RecoilRoot } from 'recoil';
// CSS
import LayoutRootPage from './LayoutRootPage';

export default function RecoilRootPage(){
    return (
        <RecoilRoot>
            <LayoutRootPage />
        </RecoilRoot>
    );
}
