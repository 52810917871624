// React
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
// Type
import { apiConfigType } from '../type/apiConfigType';
import { vectorDbTypes } from '../type/vectorDbTypes';
import { selectedDbType } from '../type/selectedDbType';
// Atom
import { vectorDbApiConfigAtom } from '../atom/vectorDbApiConfigAtom';
import { vectorDbAtom } from '../atom/vectorDbAtom';
import { selectedDbAtom } from '../atom/selectedDbAtom';
// LocalStorage
import { setApiConfigLS } from '../local_storage/useApiConfigLS';
// Utility
import objectCompare from '../utils/objectCompare';
import checkVantiqApi from '../utils/checkVantiqApi';

export default function VectorDbApiConfigUi(){
    // パラメータ
    const localStorageKey = 'vectorDbApiConfig';
    // 保存ボタン活性処理用のState
    const [saveDisabledState, setSaveDisabledState] = useState<boolean>(true);
    // 設定保持用のRecoil
    const [configRecoil, setConfigRecoil] = useRecoilState<apiConfigType>(vectorDbApiConfigAtom);
    // 設定一時保持用のState
    const [configState, setConfigState] = useState<apiConfigType>(configRecoil);
    // DB保存用のRecoil
    const setVectorDbRecoil = useSetRecoilState<vectorDbTypes>(vectorDbAtom);
    // 選択済みDB保持用のRecoil
    const setSelectedDbRecoil = useSetRecoilState<selectedDbType>(selectedDbAtom);

    // 入力ボックス入力時の処理
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.dataset.key;
        const value = event.target.value;
        if(key){
            const newConfig: object = {
                [key]: value
            }
            const newConfigs: apiConfigType = {
                ...configState
                , ...newConfig
            }
            setConfigState(newConfigs);
            if(objectCompare(newConfigs, configState)){
                setSaveDisabledState(true);
            }else{
                setSaveDisabledState(false);
            }
        }
    }
    // 保存ボタン押下時の処理
    const handleSaveConfig = async () => {
        // 保存ボタンの非活性化
        setSaveDisabledState(true);
        // 疎通チェック
        try{
            const vectorDb = await checkVantiqApi(configState.endpoint, configState.token);
            setVectorDbRecoil(vectorDb.data);
            setSelectedDbRecoil(0 < vectorDb.data[0].value.length ? vectorDb.data[0].value : '');
            configState.isActive = true;
        }catch(error: any){
                setVectorDbRecoil([]);
                setSelectedDbRecoil('');
                configState.isActive = false;
        }
        // LocalStorageとRecoilに保存
        console.log(configState);
        setApiConfigLS(localStorageKey, configState);
        setConfigRecoil(configState);
    }

    return (
        <>
            <div className={'sub-content-wrapper'}>
                <div>
                    <h3>ベクターDBエンドポイント</h3>
                    <p><span>VantiqのベクターDB一覧取得用のエンドポイントが存在する場合は入力してください。</span></p>
                    <input
                        className={classNames(((!configRecoil.isActive && configRecoil.endpoint) || (!configRecoil.isActive && configRecoil.token)) && 'errorStyle')}
                        data-key={'endpoint'}
                        defaultValue={configRecoil.endpoint}
                        placeholder={'例：https://dev.vantiq.com/api/v1/resources/procedures/getVectorDB'}
                        onChange={handleChangeInput}
                    >
                    </input>
                    {((!configRecoil.isActive && configRecoil.endpoint) || (!configRecoil.isActive && configRecoil.token)) && <p className='errorStyle'><span>入力したエンドポイントまたはアクセストークンが間違っています。</span></p>}
                </div>
                <div>
                    <h3>ベクターDBアクセストークン</h3>
                    <p><span>VantiqのベクターDB用のアクセストークンを入力してください。</span></p>
                    <input
                        className={classNames(((!configRecoil.isActive && configRecoil.token) || (!configRecoil.isActive && configRecoil.endpoint)) && 'errorStyle')}
                        data-key={'token'}
                        defaultValue={configRecoil.token}
                        placeholder={'例：zzSDpN_XCyO57nQGJXo3NPlb31gTRIf1iAnoyBrnDTc='}
                        onChange={handleChangeInput}
                    >
                    </input>
                    {((!configRecoil.isActive && configRecoil.token) || (!configRecoil.isActive && configRecoil.endpoint)) && <p className='errorStyle'><span>入力したエンドポイントまたはアクセストークンが間違っています。</span></p>}
                </div>
                <div className='button-wrapper'>
                    <button onClick={handleSaveConfig} disabled={saveDisabledState}>保存</button>
                </div>
            </div>
        </>
    );
}
