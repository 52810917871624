// React
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
// Atom
import { chatHistoryAtom } from '../atom/chatHistoryAtom';
import { conversationIdAtom } from '../atom/conversationIdAtom';
// Type
import { messagesStateType } from '../type/chatTypes';
import { conversationIdType } from '../type/conversationIdType';
import { deleteChatHistoryLS } from '../local_storage/useChatHistoryLS';
import { deleteConversationIdLS, setConversationIdLS } from '../local_storage/useConversationIdLS';
// LocalStorage

export default function RemoveChatHistoryUi(){
    // チャット履歴の削除処理のState
    const [chatHistoryRecoil, setChatHistoryRecoil] = useRecoilState<messagesStateType>(chatHistoryAtom);
    // 削除ボタン用の活性処理のState
    const [removeDisabledState, setRemoveDisabledState] = useState<boolean>((chatHistoryRecoil.length === 0));
    // ConversationId管理用のRecoil
    const setConversationIdRecoil = useSetRecoilState<conversationIdType>(conversationIdAtom);
    // 削除ボタン押下時の処理
    const removeChatHistory = () => {
        // 削除ボタンの非活性処理
        setRemoveDisabledState(true);
        // チャット履歴の削除
        deleteChatHistoryLS();
        setChatHistoryRecoil([]);
        // ConversationIdの削除
        deleteConversationIdLS();
        setConversationIdRecoil('');
    }

    return (
        <div className='sub-content-wrapper'>
            <div>
                <h3>チャット履歴の削除</h3>
                <p><span>チャットの履歴をすべて削除します。</span></p>
            </div>
            <div className='button-wrapper'>
                <button onClick={removeChatHistory} disabled={removeDisabledState}>削除</button>
            </div>
        </div>
    );
}
