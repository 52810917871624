import axios from 'axios';

export default async function getAiTolk(endpoint: string, token: string, message: string, conversationId: string, db: string){
    const headers = {
        'Content-Type': 'application/json'
        , 'Authorization': 'Bearer ' + token
    }
    const body: object = {
        message
        , conversationId
        , db
    }
    console.log(body);
    const response = axios.post(endpoint, body, {headers: headers});
    return response;
}
