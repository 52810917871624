import { useEffect } from "react";

export default function PageCloseAlertDialog(){
    // onBeforeunloadイベントのハンドラー関数
    const handleBeforeUnload = (e: any) => {
        // ページ遷移やリロードのキャンセル
        e.preventDefault();
        // カスタムメッセージの設定（ブラウザによっては無視される）
        e.returnValue = "本当にページを離れますか？";
    };

    // コンポーネントがマウントされた時にイベントを登録
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        // コンポーネントがアンマウントされた時にイベントを解除
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return null;
}
