import './css/setting_ui.css';
import ChatApiConfigUi from './ChatApiConfigUi';
import VectorDbApiConfigUi from './VectorDbApiConfigUi';
import MaxChatHistoryUi from './MaxChatHistoryUi';
import RemoveChatHistoryUi from './RemoveChatHistoryUi';

export default function SettingUi(){
    return (
        <div className='setting_ui-top-wrapper'>
            <div className='title-wrapper'>
                <h2>設定</h2>
            </div>
            <div className='content-wrapper'>
                <ChatApiConfigUi />
                <VectorDbApiConfigUi />
                <MaxChatHistoryUi />
                <RemoveChatHistoryUi />
            </div>
        </div>
    );
}
