export default function dateToJpString(date: Date){
    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric'
        , month: 'short'
        , day: 'numeric'
        , weekday: 'short'
    };
    const dateString = date.toLocaleDateString('ja-JP', dateOptions);
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const datetimeString = `${dateString} ${hour}時${minute}分${second}秒`;

    return datetimeString;
}
