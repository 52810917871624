// React
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// Type
import { isMobileType } from '../type/isMobileType';
// Atom
import { isMobileAtom } from '../atom/isMobileAtom';
// React Component
import HeaderLog from './HeaderLog';
// CSS
import './css/navigation_list.css';
// Image
import { ReactComponent as ChatIcon } from './img/chat_icon.svg';
import { ReactComponent as DustboxIcon } from './img/dustbox_icon.svg';
import { ReactComponent as SettingIcon } from './img/setting_icon.svg';

export default function NavigationList(){
    // レスポンシブ関連処理
    const isMobileRecoil = useRecoilValue<isMobileType>(isMobileAtom);
    const spTitle:JSX.Element = (<h2><HeaderLog /></h2>)
    const pcTitle:JSX.Element = (<h1><HeaderLog /></h1>)

    return (
        <div className='navigation_ui-top-wrapper'>
            <NavLink to='/'>
                {isMobileRecoil && spTitle}
                {!isMobileRecoil && pcTitle}
            </NavLink>
            <div className='navigation_ui-navigation-wrapper'>
                <div><NavLink to='/'><ChatIcon /><span>チャット</span></NavLink></div>
                <div><NavLink to='/setting'><SettingIcon /><span>設定</span></NavLink></div>
            </div>
        </div>
    );
}
