// React
import { useRecoilState } from 'recoil';
import { Drawer, Box } from '@mui/material';
// Type
import { drawerOpenStatusType } from '../type/drawerOpenStatusType';
// Atom
import { drawerOpenStatusAtom } from '../atom/drawerOpenStatusAtom';
// React Component
import NavigationList from './NavigationList';
// CSS

export default function NavigationMobile(){
    // ドロワー処理関連
    const [drawerOpenStatus, setDrawerOpenStatus] = useRecoilState<drawerOpenStatusType>(drawerOpenStatusAtom);
    const handleDraw = () => setDrawerOpenStatus(!drawerOpenStatus);

    return (
        <Drawer anchor='left' open={drawerOpenStatus} onClose={handleDraw}>
            <Box onClick={handleDraw}>
                <NavigationList />
            </Box>
        </Drawer>
    );
}
