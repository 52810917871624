import axios from 'axios';

export default async function checkVantiqApi(endpoint: string, token: string, body: object = {}){
    const headers = {
        'Content-Type': 'application/json'
        , 'Authorization': 'Bearer ' + token
    }
    const response = axios.post(endpoint, body, {headers: headers});
    return response;
}
