// React Component
import ChatUiVectorDbField from './ChatUiVectorDbField';
import ChatUiViewField from './ChatUiViewField';
import ChatUiInputField from './ChatUiInputField';
// CSS
import './css/chat_ui.css';

function ChatUi(){
    return (
        <>
            <div className='chat_ui-top-wrapper'>
                <ChatUiVectorDbField />
                <ChatUiViewField />
                <ChatUiInputField />
            </div>
        </>
    );
}

export default ChatUi;
