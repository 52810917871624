// React
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
// Type
import { selectedDbType } from '../type/selectedDbType';
import { vectorDbTypes } from '../type/vectorDbTypes';
// Atom
import { selectedDbAtom } from '../atom/selectedDbAtom';
import { vectorDbAtom } from '../atom/vectorDbAtom';
// LocalStorage
import { setSelectedDbLS } from '../local_storage/useSelectedDbLS';
// CSS
import './css/chat_ui_vector_db_field.css';

export default function ChatUiVectorDbField(){
    // DBの取得
    const vectorDbRecoil = useRecoilValue<vectorDbTypes>(vectorDbAtom);
    // セレクトリスト活性処理用のState
    const [selectDisabledState, setSelectDisabledState] = useState<boolean>(vectorDbRecoil.length === 0);
    useEffect(() => {
        setSelectDisabledState(vectorDbRecoil.length === 0);
    }, [vectorDbRecoil]);
    // 選択済みDB保持用のRecoil
    const [selectedDbRecoil, setSelectedDbRecoil] = useRecoilState<selectedDbType>(selectedDbAtom);
    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // setSelectedDbLS(event.target.value);
        setSelectedDbRecoil(event.target.value);
    }

    return (
        <>
            <div className='chat_vectordb-field'>
                <select value={selectedDbRecoil} disabled={selectDisabledState} onChange={(event) => handleSelect(event)}>
                    {
                        vectorDbRecoil.map(db => (
                            <React.Fragment key={db.value}>
                                <option value={db.value}>{db.label}</option>
                            </React.Fragment>
                        ))
                    }
                </select>
            </div>
        </>
    );
}
