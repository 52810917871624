import React from 'react';
import SettingUi from './SettingUi';

export default function SettingPage(){
    return (
        <React.StrictMode>
            <SettingUi />
        </React.StrictMode>
    );
}
